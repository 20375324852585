<template>
  <Layout :tituloPagina="`Network | Dispositivos para hogar | Tipos | ${ modo == 'nuevo' ? 'Nuevo' : 'Edición' }`">
    <div id="edicionTipoDhs" class="row">
      <div class="col-lg-5 col-md-6">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">
              {{ modo == 'nuevo' ? 'Nuevo' : 'Edición de' }}
              tipo de dispositivos para hogar
            </h4>
            <div>
              <div class="dropdown">
                <a
                  class="dropdown-toggle"
                  href="javascript: void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <eva-icon
                    name="more-horizontal-outline"
                    data-eva-width="20"
                    data-eva-height="20"
                    :class="{
                      'fill-dark': $store.state.layout.layoutMode == 'light',
                      'fill-white': $store.state.layout.layoutMode == 'dark'
                    }"
                  ></eva-icon>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      onclick="return false"
                      @click="modo == 'nuevo' ? guardar() : actualizar()"
                      v-if="!bandera_spinner"
                    >
                      <i class="mdi mdi-content-save text-success"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </a>
                    <span class="dropdown-item disabled" v-if="bandera_spinner">
                      <i class="mdi mdi-concourse-ci mdi-spin"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </span>
                  </li>
                  <li>
                    <a
                      class="dropdown-item" href="#"
                      onclick="return false;" @click="atras()"
                    >
                      <i class="mdi mdi-close text-dark"></i>
                      Cerrar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body">
            <label>Nombre</label>
            <input
              ref="nombre"
              class="form-control"
              name="nombre"
              v-model="tipo.nombre"
              placeholder="Nombre del tipo del dispositivos para hogar"
            />
          </div>
        </div>

        <div class="text-right">
          <div class="btn-group">
            <button class="btn btn-secondary" @click="atras()">
              <i class="mdi mdi-chevron-left"></i>
              Atras
            </button>

            <button
              class="btn btn-success"
              @click="modo == 'nuevo' ? guardar() : actualizar()"
              :disabled="bandera_spinner"
            >
              <i
                class="mdi"
                :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
              ></i>
              {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main"
import TipoDhsSrv from '@/services/TipoDhsSrv.js'
export default {
  name: 'EdicionTipoDhs',
  components: { Layout },
  data() {
    return {
      modo: 'nuevo',
      tipo: {
        nombre: ''
      },
      tipoInicial: {},
      bandera_spinner: false
    }
  },

  created: function() {
    var self = this

    // Carga del modo
    if (this.$route.path.indexOf('nuevo') == -1) self.modo = 'edicion'

    self.tipoInicial = Object.assign({}, self.tipo)

    if (self.modo == 'edicion') self.cargarTipoAEditar()
  },

  methods: {
    actualizar: function() {
      var self = this
      var tipo = Object.assign({}, self.tipo)

      self.bandera_spinner = true

      if(tipo.nombre == '') {
        iu.msg.warning('se necesita el nombre para el tipo DH')
        self.$refs.nombre.focus()
        self.bandera_spinner = false
        return
      }

      TipoDhsSrv.actualizar(tipo).then(response => {
        iu.msg.success('Se actualizó correctamente')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo actualizar'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },
    atras: function() {
      this.$router.go(-1)
    },
    cargarTipoAEditar: function() {
      var self = this
      let idTipo = self.$route.params.id
      
      TipoDhsSrv.tipoJSON(idTipo).then(response => {
        self.tipo = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje = '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    },
    guardar: function() {
      var self = this
      var tipo = Object.assign({}, self.tipo)

      self.bandera_spinner = true

      if(tipo.nombre == '') {
        iu.msg.warning('se necesita el nombre para el tipo DH')
        self.$refs.nombre.focus()
        self.bandera_spinner = false
        return
      }

      TipoDhsSrv.guardar(tipo).then(response => {
        iu.msg.success('Se guardó correctamente')
        self.tipo = Object.assign({}, self.tipoInicial)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo guardar'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    }
  }
}
</script>

<style scoped>

</style>